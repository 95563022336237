import React from "react";
import WorkCar from '../Images/Appliance-repair-work-car-cropped.webp'
import "./Description.css"

const Description = () => {
  return <div id="desc-container">
    <img loading="lazy" id="desc-photo" src={WorkCar} alt="Work car with Major Appliance Repair logo"></img>
    <div>
      <h1 id="desc-header">Meet Major Appliance Repair</h1>
      <p id="desc-text"> Major Appliance Repair is your trusted local company for expert appliance repair solutions. With years of experience and a commitment to excellence, we specialize in providing services like <a href="https://majorappliancerepair.info/services/washer-repair/">washing machine repair</a>, <a href="https://majorappliancerepair.info/services/dryer-repair/">dryer repair</a>, <a href="https://majorappliancerepair.info/services/refrigerator-repair/">refrigerator repair</a>, <a href="https://majorappliancerepair.info/services/range-repair/">range repair</a>, <a href="https://majorappliancerepair.info/services/cooktop-repair/">cooktop repair</a>,  <a href="https://majorappliancerepair.info/services/range-repair/">oven repair</a>, <a href="https://majorappliancerepair.info/services/dishwasher-repair/">dishwasher repair</a>,  washing machine maintenance, dryer maintenance, ventilation cleaning, appliance odor removal and other.</p>
    </div>
  </div>
};

export default Description;
