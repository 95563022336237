import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "./node_modules/react-router-dom"
import App from "./App";

ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
);

