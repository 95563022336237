import React from 'react'
import cooktop1 from '../../Images/appliances/thor-cooktop.webp'
import cooktop2 from '../../Images/appliances/whirlpool-cooktop.webp'
import Charges from '../../Sections/Charges';
import ServiceArea from '../../Sections/ServiceArea';
import { Helmet } from 'react-helmet';
import { rangeLD } from '../JSON-LD';
import './Appliance.css'

const CooktopRepair = () => {
  const jsonLdString = JSON.stringify(rangeLD)

  return <div className="page-container">
    <Helmet>
        <script type="application/ld+json">{jsonLdString}</script>
        <title>Cooktop Repair in Seattle</title>
        <meta name="description" content="Is your cooktop malfunctioning? Learn how to troubleshoot and fix common issues like burner not lighting, uneven heating, and cracked glass. Contact our professional cooktop repair services." />
        <link rel="canonical" href="https://majorappliancerepair.info/services/cooktop-repair/"></link>
        <link rel="preload" href={cooktop1} as="image" />
        <link rel="preload" href={cooktop2} as="image" />
      </Helmet>
  <div className="appliance-container" >
  <div className="photos-container">
    <img loading="lazy" src={cooktop1} alt="Cooktop repair in Seattle"/>
    <img loading="lazy" src={cooktop2} alt="Cooktop repair in Bellevue"/>
  </div>
  <div className="text-container">
  <header>
        <h1>Cooktop Repair</h1>
        <p>If your cooktop is malfunctioning, it can interrupt your cooking routine. Whether it's a gas, electric, or induction cooktop, there are various common issues that can arise. In this guide, we’ll go over the most frequent cooktop problems and how to troubleshoot or repair them.</p>
    </header>

    <section>
        <h2>1. Cooktop Burner Won’t Light (Gas Cooktop)</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Clogged burner ports</li>
            <li>Faulty igniter</li>
            <li>Dirty spark electrode</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>Clean the burner ports with a small brush or pin to remove any debris.</li>
            <li>Check the igniter for damage and replace it if necessary.</li>
            <li>Clean the spark electrode with a cloth to ensure proper sparking.</li>
        </ul>
    </section>

    <section>
        <h2>2. Electric Cooktop Not Heating</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Burnt-out heating element</li>
            <li>Faulty thermostat</li>
            <li>Loose or disconnected wires</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>Inspect the heating element for visible damage. If it's burnt out, replace it.</li>
            <li>Test the thermostat with a multimeter. Replace it if faulty.</li>
            <li>Ensure all wires are connected properly and tightly.</li>
        </ul>
    </section>

    <section>
        <h2>3. Induction Cooktop Not Turning On</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Power supply issues</li>
            <li>Defective power board or control board</li>
            <li>Cookware incompatibility</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>Check the power supply and make sure the cooktop is plugged in and receiving power.</li>
            <li>If the control board is malfunctioning, contact a technician for repair or replacement.</li>
            <li>Ensure your cookware is induction-compatible (made of ferrous metal).</li>
        </ul>
    </section>

    <section>
        <h2>4. Cooktop Making Strange Noises</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Loose burner components</li>
            <li>Gas leaks in a gas cooktop</li>
            <li>Electrical issues in an electric cooktop</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>Check the burner for any loose components and tighten them if necessary.</li>
            <li>If you suspect a gas leak, turn off the cooktop immediately and call a technician.</li>
            <li>For electric cooktops, ensure that the power supply is stable and not causing interference.</li>
        </ul>
    </section>

    <section>
        <h2>5. Cooktop Burner Not Heating Evenly</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Uneven burner coil or element</li>
            <li>Power supply issue</li>
            <li>Dirty or clogged burner</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>Ensure the burner coil or element is not damaged or uneven. If it is, replace it.</li>
            <li>Check the power supply for any inconsistencies.</li>
            <li>Clean the burner to remove any debris or grease buildup that may be affecting heat distribution.</li>
        </ul>
    </section>

    <section>
        <h2>6. Cooktop with Cracked Glass Surface</h2>
        <p><strong>Possible Causes:</strong></p>
        <ul>
            <li>Impact or physical damage</li>
            <li>Excessive heat exposure</li>
        </ul>
        <p><strong>How to Fix It:</strong></p>
        <ul>
            <li>For small cracks, some repair kits are available, but if the glass is significantly damaged, it’s safer to replace the glass top.</li>
            <li>Avoid placing heavy items on the cooktop and always wait for it to cool down before cleaning.</li>
        </ul>
    </section>

    <section>
        <h2>7. When to Call a Professional for Cooktop Repair</h2>
        <p>While some cooktop issues can be fixed with DIY methods, others may require professional repair. Contact a technician if:</p>
        <ul>
            <li>Your cooktop is still not heating after troubleshooting.</li>
            <li>The cooktop makes persistent noises or shows signs of malfunction.</li>
            <li>There is a gas leak or electrical issue that you are unable to safely address.</li>
        </ul>
    </section>

    <section>
        <h2>8. Why Choose Us for Your Cooktop Repair?</h2>
        <ul>
        <li><strong>We Are Licencensed, Bonded and Insured:</strong> Check out our license - <a href="https://secure.lni.wa.gov/verify/Detail.aspx?UBI=604560224&LIC=MAJORAR785C2&SAW=" target="_blank" rel="noreferrer">MAJORAR785C2</a> !</li>
        <li><strong>Great Reputation:</strong> We are highly <a href="https://biz.yelp.com/biz_info/wGGpN1FF5vLLv4D6PYuj4Q" target="_blank" rel="noreferrer"> recommended </a> by your neighbors!</li>            <li><strong>Expert Technicians:</strong> Our professionals are skilled in repairing all types of cooktops, including gas, electric, and induction models.</li>
            <li><strong>Fast & Reliable Service:</strong> We offer same-day service for emergency repairs.</li>
            <li><strong>Affordable Pricing:</strong> Get a transparent quote with no hidden fees.</li>
            <li><strong>High-Quality Parts:</strong> We only use genuine replacement parts to ensure longevity and performance.</li>
            <li><strong>Satisfaction Guarantee:</strong> We stand behind our work with a repair warranty for your peace of mind.</li>
        </ul>
    </section>

    <section>
        <h2>Need Cooktop Repair? Contact Us Today!</h2>
        <p>Don't let a broken cooktop interrupt your cooking. Contact us today for professional cooktop repair services that will have your cooktop running like new!</p>
    </section>
  </div>
  <div className="photos-container-mobile">
    <img loading="lazy" src={cooktop1} alt="Cooktop repair in Seattle"/>
    <img loading="lazy" src={cooktop2} alt="Cooktop repair in Bellevue"/>
  </div>
</div>
<Charges />
<ServiceArea />
</div>
};

export default CooktopRepair;
