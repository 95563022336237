export const cityLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert repair services for major appliances including refrigerators, washing machines, dryers, dishwashers, and more.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "areaServed": {
      "@type": "Place",
      "address": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
    "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Kitchen refrigerator Repair services",
    "itemListElement": [
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas Cooktop repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas Cooktop maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas Cooktop service"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric Cooktop repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric Cooktop maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric Cooktop service"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas stove repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas stove maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas stove service"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric stove repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric stove maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric stove service"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas range repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas range maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Gas range service"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric range repair"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric range maintenance"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Electric range service"
        }
      }
    ]
  }
}

export const dishwasherLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert dishwasher repair services for all major brands, including Bosch, Whirlpool, LG, Samsung, and KitchenAid. We fix dishwashers that won’t start, don’t clean properly, leak water, or make unusual noises. Fast, reliable service in Seattle, Bellevue, Redmond, and surrounding areas.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "serviceArea": {
      "@type": "Place",
      "addressRegion": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
  "acceptsReservations": "True",
  "serviceType": [
    "Dishwasher repair",
    "Dishwasher maintenance",
    "Built-in dishwasher repair",
    "Portable dishwasher repair",
    "High-efficiency dishwasher repair"
],
"hasOfferCatalog": {
  "@type": "OfferCatalog",
  "name": "Dishwasher Repair Services",
  "itemListElement": [
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dishwasher Not Cleaning Properly Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dishwasher Not Draining Fix"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dishwasher Water Leak Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dishwasher Making Loud Noises Repair"
          }
          }
      ]
  }
}

export const dryerLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert dryer repair services for all major brands, including Whirlpool, Samsung, LG, Maytag, and GE. We fix heating issues, drum malfunctions, loud noises, poor drying performance, and more. Fast, reliable service in Seattle, Bellevue, Redmond, and surrounding areas.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "serviceArea": {
      "@type": "Place",
      "addressRegion": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
  "acceptsReservations": "True",
  "serviceType": [
    "Dryer repair",
    "Gas dryer repair",
    "Electric dryer repair",
    "Stackable dryer repair",
    "High-efficiency dryer repair",
    "Tumble dryer repair"
],
"hasOfferCatalog": {
  "@type": "OfferCatalog",
  "name": "Dryer Repair Services",
  "itemListElement": [
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dryer Not Heating Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dryer Drum Not Spinning Fix"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Loud Noise Dryer Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Dryer Vent Cleaning and Maintenance"
          }
          }
      ]
  }
}

export const rangeLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert oven, range, and cooktop repair services for all major brands, including Wolf, Viking, GE, Samsung, and Whirlpool. We fix heating issues, faulty burners, broken igniters, temperature inconsistencies, and more. Fast, reliable service in Seattle, Bellevue, and surrounding areas.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "serviceArea": {
      "@type": "Place",
      "addressRegion": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
  "acceptsReservations": "True",
  "serviceType": [
        "Oven repair",
        "Range repair",
        "Cooktop repair",
        "Stovetop repair",
        "Gas range repair",
        "Electric oven repair"
    ],
  "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Oven, Range, and Cooktop Repair Services",
      "itemListElement": [
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Oven Heating Issue Repair"
              }
          },
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Gas Range Igniter Repair"
              }
          },
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Electric Cooktop Burner Replacement"
              }
          },
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Stovetop Temperature Calibration"
              }
          }
      ]
  }
}

export const refrigeratorLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert refrigerator repair services for all major brands, including Sub-Zero, Whirlpool, GE, and Samsung. We fix cooling issues, compressor failures, water leaks, ice maker problems, and temperature fluctuations. Fast, reliable, and affordable service in Seattle, Bellevue, and surrounding areas.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "serviceArea": {
      "@type": "Place",
      "addressRegion": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
  "acceptsReservations": "True",
  "serviceType": [
        "Refrigerator repair",
        "Refrigerator maintenance",
        "Refrigerator diagnostics"
    ],
  "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Refrigerator Repair Services",
      "itemListElement": [
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Refrigerator Cooling Issue Repair"
              }
          },
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Refrigerator Compressor Repair"
              }
          },
          {
              "@type": "Offer",
              "itemOffered": {
                  "@type": "Service",
                  "name": "Refrigerator Door Seal Replacement"
              }
          }
      ]
  }
}

export const washerLD = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://majorappliancerepair.info/#business",
  "name": "Major Appliance Repair",
  "description": "Expert washer repair services for all major brands, including Whirlpool, LG, Samsung, Maytag, and GE. We fix drainage issues, spin cycle malfunctions, water leaks, electrical problems, and more. Fast, reliable service in Seattle, Bellevue, Redmond, and surrounding areas.",
  "url": "https://majorappliancerepair.info/",
  "image": "https://majorappliancerepair.info/static/media/Major-appliance-repair-logo.98af444363f3cf073d0c.webp",
  "telephone": "+1-425-364-6644",
  "priceRange": "$200-$500",
  "openingHours": [
    "Mo-Fr 09:00-17:00"
  ],
  "address": {
      "@type": "PostalAddress",
      "streetAddress": "10961 NE 2nd Pl",
      "addressLocality": "Bellevue",
      "addressRegion": "WA",
      "postalCode": "98004",
      "addressCountry": "US"
  },
  "serviceArea": {
      "@type": "Place",
      "addressRegion": "King County, WA"
  },
  "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.6154",
      "longitude": "-122.1950"
  },
  "sameAs": [
      "https://www.facebook.com/major.repairing",
      "https://www.yelp.com/biz/major-appliance-repair-bellevue"
    ],
  "acceptsReservations": "True",
  "serviceType": [
    "Washer repair",
    "Washing machine repair",
    "Front-load washer repair",
    "Top-load washer repair",
    "High-efficiency washer repair",
    "Stackable washer repair"
],
"hasOfferCatalog": {
  "@type": "OfferCatalog",
  "name": "Washer Repair Services",
  "itemListElement": [
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Washer Not Draining Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Washer Spin Cycle Fix"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Washing Machine Leak Repair"
          }
      },
      {
          "@type": "Offer",
          "itemOffered": {
              "@type": "Service",
              "name": "Electrical Issue Repair for Washers"
          }
          }
      ]
  }
}